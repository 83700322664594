import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from '../../../shared/services/user/user.service';
import { UserRole } from '@shared/models/user/user-roles';

@Injectable({
  providedIn: 'root'
})
export class ExpenseMappingAdminGuard {

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean> {
    return this.userService.getUserActionMenu().pipe(
      map(res => {
        if (typeof res == 'undefined') return false;

        if (res.userRole == UserRole.Administrator) {
          return true;
        } else {
          this.router.navigate(['not-found']);
        }
      }),
      catchError((_err) => {
        return of(false);
      })
    );
  }
}