export enum UserRole {
    Administrator = "Administrator",
    Auditor = "Auditor",
    AuditLead = "AuditLead",
    AuditSupport = "AuditSupport",
    EditOnly = "EditOnly",
    EMSUser = "EMSUser",
    FinancialAdmin = "FinancialAdmin",
    FraudAuditor = "FraudAuditor",
    ProjectApproverAdmin = "ProjectApproverAdmin",
    ReadOnly = "ReadOnly",
    SecurityProvisioning = "SecurityProvisioning",
    SuperUser = "SuperUser",
    TEAuditReviewerAFS = "TEAuditReviewerAFS",
    TEAuditReviewerMD = "TEAuditReviewerMD",
    TEAuditReviewerNonMD = "TEAuditReviewerNonMD",
    User = "User",
    VipAuditor = "VipAuditor",
    Support = "Support",
    None = "",
    SAPSupportAdmin="SAPSupportAdmin"
}

export namespace UserRole {
    export function getValueByString(role: string): UserRole {
        return (UserRole as any)[Object.keys(UserRole).filter(k => (UserRole as any)[k] === role)[0]];
    }
}