import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExpenseGridEventsService } from '../../../../myte-expenses/shared/services/events/expense-grid-events.service';
import { ExpenseGridService } from '../../../../myte-expenses/shared/services/grids/expense-grid.service';
import { ReceiptRequirement, ReceiptRequirementTypes } from '../../../../shared/models/expense/receipt/expense-receipt-requirement';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { FocusService } from '../../../../shared/services/focus/focus.service';
import { ReceiptsService } from '../../../../shared/services/receipts/receipts.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { iconsReceipt } from '../expense-grid-expense-type-renderer/constants/expenseTypeIcons.constants';

@Component({
  selector: 'myte-expense-grid-receipt-renderer',
  templateUrl: './expense-grid-receipt-renderer.component.html',
  styleUrls: ['./expense-grid-receipt-renderer.component.sass']
})
export class ExpenseGridReceiptRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<ExpenseGridItem, ReceiptRequirement>;
  public isGroupByTrip: boolean;
  public editable: boolean = true;
  public countryKey: string;
  public isGCUser: boolean;
  public value: ReceiptRequirement;
  public expenseId: string;
  public canViewUploadReceipts: boolean;
  public timeReportStatus: string;
  public periodEnd: Date;
  selected: TemplateRef<any>;
  templates: any;
  tooltip = {
    camera: '',
    envelope: ''
  };
  icon = {
    electronic: '',
    physical: ''
  };

  @ViewChild('None', { static: true }) private noneTemplate: TemplateRef<any>;
  @ViewChild('Neither', { static: true }) private neitherTemplate: TemplateRef<any>;
  @ViewChild('Electronic', { static: true }) private electronicTemplate: TemplateRef<any>;
  @ViewChild('Physical', { static: true }) private physicalTemplate: TemplateRef<any>;
  @ViewChild('Both', { static: true }) private bothTemplate: TemplateRef<any>;
  @ViewChild('Either', { static: true }) private eitherTemplate: TemplateRef<any>;
  @ViewChild('inputfile') private fileInput: ElementRef<HTMLElement>;

  constructor(
    private gridService: ExpenseGridService,
    private receiptService: ReceiptsService,
    public expenseGridEventService: ExpenseGridEventsService,
    public focusService: FocusService,
    public cacheService: GlobalCacheService,
    public timeReportStoreService: TimeReportStoreService,
    public actionService: ActionStoreService) {
  }

  public invokeParentMethod(): void {
    this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`);
  }

  public agInit(params: ICellRendererParams<ExpenseGridItem, ReceiptRequirement>): void {
    this.params = params;
    this.periodEnd = this.params.context?.expenseGridParamsService?.periodEnd;
    this.templates = {
      'None': this.noneTemplate,
      'Neither': this.neitherTemplate,
      'Electronic': this.electronicTemplate,
      'Physical': this.physicalTemplate,
      'Both': this.bothTemplate,
      'Either': this.eitherTemplate
    };
    this.value = params.value;
    this.selected = this.templates[ReceiptRequirementTypes[this.value.type]];

    this.isGroupByTrip = this.params.context?.expenseGridParamsService?.isGroupByTrip;

    this.expenseId = this.params.data.id;
    this.editable = false;
    this.receiptService.setEditable(params, this).subscribe(editable => {
      this.editable = editable;
    });
    this.countryKey = this.params.context?.expenseGridParamsService?.countryUser;

    this.timeReportStatus = this.params.context?.expenseGridParamsService?.timeReportStatus;

    if(this.timeReportStatus != undefined) {
      this.canViewUploadReceipts = this.params.context?.expenseGridParamsService?.canViewUploadReceipts;
      this.isGCUser = this.params.context?.expenseGridParamsService?.shouldShowReceiptType;
      this.setIcons();
      this.setIconsTooltip();
    }
  }

  public refresh(): boolean {
    return false;
  }

  public isSalarySupplementRecieptPolicy710(): boolean {
    return this.params?.data?.type.value === 'Salary Supplement';
  }

  public handleClick(): void {
    if (this.isSalarySupplementRecieptPolicy710() && this.params.value.type != ReceiptRequirementTypes.Both) {
      this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
        shouldInitialize: true,
        periodEnd: this.periodEnd
      });
      return;
    }
    this.focusService.updateLastSelectedCell(this.params, FocusService.getColumnIndexByColId(this.params?.api, 'receipt'));
    if (this.icon.electronic == 'camera-required' && this.editable && !this.isGCUser) {
      this.fileInput.nativeElement.click();
    } else {
      this.params.node.setSelected(true, true);
      this.expenseGridEventService.dispatchShowUploadReceiptPopupEvent(this.icon.electronic);
    }
  }

  public onCancelFileUpload(): void {
    this.params.node.setSelected(true, true);
    this.expenseGridEventService.dispatchShowUploadReceiptPopupEvent();
  }

  public onFileChange(files): void {
    this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
    this.expenseGridEventService.dispatchUploadReceiptStartedEvent();
    this.receiptService.attachReceipts(files, this.expenseId, '')
      .subscribe(() => {
        this.expenseGridEventService.dispatchUploadReceiptFinishedEvent();
        this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
          shouldInitialize: true,
          periodEnd: this.periodEnd
        });
      });
  }

  private setIcons(): void {
    // electronic icon
    if (this.canViewUploadReceipts) {
      if (this.value.electronicProvided) this.icon.electronic = 'camera-checked';
      else{
        const electronicIconKey = this.value.physicalProvided && this.value.type === ReceiptRequirementTypes.Either ? ReceiptRequirementTypes.None : this.value.type;
        this.icon.electronic = iconsReceipt[electronicIconKey];
      }
    }
    // physical icon
    var bothAndEither = [ReceiptRequirementTypes.Both, ReceiptRequirementTypes.Either,ReceiptRequirementTypes.Physical];
    if (bothAndEither.includes(this.value.type)){
      if (this.value.type === ReceiptRequirementTypes.Either && this.value.electronicProvided){
        this.icon.physical = '';
      }else
      this.icon.physical = this.value.physicalProvided ? 'envelope-checked' : 'envelope-required';
    }else this.icon.physical = '';
  }

  private setIconsTooltip(): void {
    // electronic tooltip
    if (this.value.electronicProvided) {
      this.tooltip.camera = 'An electronic receipt has been received for this expense. Click on this link to view the uploaded receipt.';
    }
    else if (ReceiptRequirementTypes[this.value.type] === 'Both' || ReceiptRequirementTypes[this.value.type] === 'Electronic' || ReceiptRequirementTypes[this.value.type] === 'Either') {
      this.tooltip.camera = !this.isGCUser ? 'Drag and drop receipt(s) here' : '';
    }
    else {
      this.tooltip.camera = 'Please click on this icon to upload electronic receipts for this expense.';
    }

    // physical tooltip
    if (this.value.physicalProvided) {
      this.tooltip.envelope = 'A physical receipt has been received for this expense.';
    } else {
      this.tooltip.envelope = 'A physical receipt is required for this expense.';
    }
  }

  public dropHandler(event): void {
    if(!this.isGCUser){
      if (!this.editable || this.isGroupByTrip) return;
      event.preventDefault();
      event.stopPropagation();
      this.removeRowClass(event, 'row-file-dragged');
      event.dataTransfer.effectAllowed = 'copyMove';

      this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
      if (this.params?.data?.type.value === 'Salary Supplement' && this.params.value.type != ReceiptRequirementTypes.Both) {
        this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
          shouldInitialize: true,
          periodEnd: this.periodEnd
        });
        return;
      }
      this.receiptService.attachReceipts(event.dataTransfer.files, this.params.data.id, '')
        .subscribe(() => {
          this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
            shouldInitialize: true,
            periodEnd: this.periodEnd
          });
        });
    }
  }

  public dragEnterHandler(event): boolean {
    if (!this.editable || this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    return true;
  }

  public dragOverHandler(event): boolean {
    if (!this.editable || this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.setRowClass(event, 'row-file-dragged');
    return true;
  }

  public dragLeaveHandler(event): boolean {
    if (!this.editable || this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.removeRowClass(event, 'row-file-dragged');
    return true;
  }

  private setRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row && !row.classList.contains(className)) {
      row.classList.add(className);
    }
  }

  private removeRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row)
      row.classList.remove(className);
  }

  private getRow = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || this.customComposedPath(event);
    if (path) {
      return path.find(p =>
        p.attributes && p.attributes.role && p.attributes.role.value == 'row' && p.attributes['row-index']);
    }
    return false;
  }

  private customComposedPath(event): any[] {
    let path = [];
    let currentElement = event.target;

    while (currentElement) {
      path.push(currentElement);
      if (currentElement.tagName === 'HTML') {
        path.push(document);
        path.push(window);
        return path;
      }
      currentElement = currentElement.parentElement;
    }
    return path;
  }

}