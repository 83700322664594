export abstract class NumberFormatUtils {
    public static decimalSeparator: string = (1.1).toLocaleString(navigator.userAgent.search(/Trident/i) > 0 ? navigator['browserLanguage'] : navigator['language']).substring(1, 2);

    public static formatNumberToLocaleString(value: number, minimumValue: any = undefined, maximumValue: any = undefined): any {
        let requiresAlternateDecimalPositioning = NumberFormatUtils.decimalSeparator == ',';
        let amountValue = value.toLocaleString(requiresAlternateDecimalPositioning ? 'de-DE' : 'en-US', { minimumFractionDigits: minimumValue, maximumFractionDigits: maximumValue });
        return amountValue;
    }

    /**
     * @param x value
     * @param n till decimal place
     * @returns roundoff value
     */
    public static toFixedTrunc(x: number, n: number): number {   
        const reg = new RegExp("^-?\\d*(?:\\.\\d{0," + n + "})?", "g");
        const a = x.toString().match(reg)[0];
        const dot = a.indexOf(".");
        if (dot === -1) {
          return Number(a + "." + "0".repeat(n));
        }
        const b = n - (a.length - dot) + 1;
        return Number(b > 0 ? (a + "0".repeat(b)) : a);
      }
}