import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, from } from 'rxjs';
import { UserService } from '@sharedServices/user/user.service';
import { ExpenseApiClient, ReceiptInput, ReceiptOutput, ReceiptImage, MissingReceiptInput } from '@sharedClients/expense-api-client';
import { LogService } from '@sharedServices/log/log.service';
import { Receipt, ExpenseReceipts } from '@sharedModels/expense/receipt/expense-receipt';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import { ReceiptFileType, MyteFile } from '@sharedModels/myte-file';
import { AdditionalDocumentation, TimeReportDocumentation } from '@sharedModels/time-report/time-report-additional-documentation';
import { TimeReportService } from '@sharedServices/time-report/time-report.service';
import { ActivatedRoute } from '@angular/router';
import { MyteBaseService } from '@sharedServices/myte-base.service';
import { GlobalEventsService } from '@sharedServices/events/global-events.service';
import { SubordinatesMode } from '@sharedModels/subordinates/subordinate-mode';
import { PeriodEndService } from '@sharedServices/periodend/periodend.service';
import { UserRole } from '@shared/models/user/user-roles';

@Injectable({
  providedIn: 'root'
})
export class ReceiptsService extends MyteBaseService {
  constructor(private api: ExpenseApiClient,
              private activatedRoute: ActivatedRoute,
              private timeReportService: TimeReportService,
              logService: LogService,
              globalCacheService: GlobalCacheService,
              globalEventsService: GlobalEventsService,
              userService: UserService,
              private periodEndService: PeriodEndService) {
    super(userService, globalCacheService, globalEventsService, logService);
  }

  /** Attach a given files to an expense
   * @param files The list of files to be attached
   * @param expenseId The Id of the expense to which the receipt will be attached
   * @param auditIssueId The Id of the issue to which the receipt will be attached
   * @returns Number that indicate the number of recipes attached successfully
  */
  public attachReceipts(files: FileList, expenseId: string, auditIssueId: string, receiptType?: string): Observable<Receipt[]> {

    if (!this.validateFiles(files)) {
      this.logService.logWarning('5 receipts maximum per expense.', true, 'No Receipt Attached');
      return of(null);
    }

    return this.createReceiptList(files, expenseId, receiptType)
      .pipe(mergeMap(receipts =>
        this.uploadReceipts(receipts.filter(r => r), auditIssueId))
      ).pipe(map(result => {
        let successfully = result.filter(r => r);
        if (successfully.length > 0)
          this.logService.showSuccessMessage('Receipt Attached', `${successfully.length} Receipt(s) uploaded successfully`);
        return successfully;
      }));
  }

  /** Use this method to retrive receipts url of a expense
   * @param expenseId The ID of the expense
   * @returns An array of Receipts with the imageUrl, but without base64 encoded.
   */
  public getReceipts(expenseId: string, requiresMessage: boolean, fileName: string = ''): Observable<ExpenseReceipts> { 
    return this.api.getReceiptsFromExpense(expenseId, requiresMessage ,this.periodEndService.getActivePeriod(), this.userEid, this.supervisorEid, this.viewMode, fileName)
      .pipe(map(receiptOutput => this.mapReceiptsOutputToReceipt(receiptOutput)))
      .pipe(catchError(err => {
        this.logService.logError('Unable to retrieve receipts for the expense', true);
        return of(null);
      }));
  }

  public getCanViewUploadReceipts():Observable<any> {
    return this.timeReportService.getTimeReportSettings(this.globalCacheService.getPeriodEnd(), this.activatedRoute.snapshot.queryParams.countryKey);
  }

  public saveMissingReceiptComments(missingReceiptInput: MissingReceiptInput): Observable<void> {
    let saveMissingReceiptRequest = this.api.saveMissingReceiptComments(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, missingReceiptInput)
      .pipe(catchError(err => {
        this.logService.logError('Unable to save missing receipt information', true);
        return of(null);
      }));

      return this.globalCacheService.handleSaveMissingReceipt(this.globalCacheService.getPeriodEnd(), saveMissingReceiptRequest);

  }

  public uploadAdditionalDocumentation(additionalInfo: AdditionalDocumentation, countryKey: string): Observable<boolean> {
    if (!this.hasImageData(additionalInfo)) {
      this.logService.logError('Unable to upload additional documentation', true);
      return of(null);
    }

    let receiptInput = new ReceiptInput();
    receiptInput.periodEnd = additionalInfo.periodEnd;
    receiptInput.enterpriseId = this.userEid;
    receiptInput.file = new ReceiptImage();
    receiptInput.file.fileName = additionalInfo.fileName;
    receiptInput.file.imageDataString = additionalInfo.getFileData();

    let uploadReceiptRequest = this.api.uploadReceipt(this.supervisorEid, this.viewMode, receiptInput)
    .pipe(map(response => {
      if(response?.staticMessage?.indexOf("The photo image is not clear.") !== -1 && response?.staticMessage != null) {
        this.logService.logError(`Unable to upload ${response?.receipts[0].fileName}. ${response?.staticMessage}`, true, 'No Receipt Attached');
        return false;
      }
      if(response?.staticMessage?.indexOf("MyTime&Expenses is experiencing issues with receipt uploads. Please try again later. If you continue to experience this issue, please contact Technology Support.") !== -1 && response?.staticMessage != null) {
        this.logService.logError(`${response?.staticMessage}`, true, 'No Receipt Attached');
        return false;
      }
      if(response?.staticMessage != null ){
        this.logService.logError(`${response?.staticMessage}`, true);
        return false;
      }
      return true;
    }))
      .pipe(catchError(err => {
        this.logService.logError('Unable to upload receipt', true);
        return of(null);
      }));

    return this.globalCacheService.handleAdditionalDocumentation(this.globalCacheService.getPeriodEnd(), uploadReceiptRequest);
  }

  public getAdditionalDocumentation(periodEnd: Date): Observable<TimeReportDocumentation> {
    return this.api.getReceiptsFromTimeReport(this.userEid, periodEnd, this.supervisorEid, this.viewMode)
      .pipe(map(receiptOutput => this.mapReceiptsOutputToDocuments(receiptOutput)))
      .pipe(catchError(err => {
        this.logService.logError('Unable to retrive additional documentation', true);
        return of(null);
      }));
  }

  public deleteReceipt(receipt: MyteFile) {
    let receiptInput = MyteFile;

    let deleteReceiptRequest = this.api.deleteReceipt(receipt.id, this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
      .pipe(map(isReceiptDeleted => isReceiptDeleted))
      .pipe(catchError(err => {
        this.logService.logError('Unable to upload receipt', true);
        return of(null);
      }));

    return this.globalCacheService.handleDeleteReceipt(this.globalCacheService.getPeriodEnd(), deleteReceiptRequest);
  }
  
  public getReceiptUrl(receiptId:any, fileName: string = ''){
    return this.api.getReceiptUrl(receiptId, this.userEid, this.supervisorEid, this.viewMode, fileName).pipe(catchError(err => {
      this.logService.logError('Unable to get receipt url', true);
      return of(null);
    }));;
  }

  /** Validate files for:
   ** 5 receipts maximum per expense
   * @param files The list of files to be attached
   * @returns True if files are valids
   */
  private validateFiles(files: FileList): boolean {
    return files.length < 6;
  }

  /** Map fileList to an array of Receipt
   * @param files The list of files to be attached
   * @param expenseId The Id of the expense to which the receipt will be attached
   * @returns Observable of receipts list. If a file is invalid it returned as undefined in the list
  */
  private createReceiptList(files: FileList, expenseId: string, receiptType: string): Observable<Receipt[]> {
    let list = [];
    for (let i = 0; i < files.length; i++) {
      list.push(files[i]);
    }
    return forkJoin(
      list.map(file => {
        return this.readFile(file, (data) => {
          try {
            let receipt = new Receipt(file, this.globalCacheService);
            receipt.expenseId = expenseId;
            receipt.receiptType = receiptType;
            receipt.processFileData(data);
            return receipt;
          } catch (err) {
            this.logService.logError(err, true);
            return null;
          }
        });
      })
    );
  }

  /** Read data from a file
   * @param file The file to be processed
   * @param cb The callback that will be executed when the data is ready
   * @returns Observable of the type retrived from callback function
  */
  private readFile(file: File, cb): Observable<any> {
    return new Observable<any>((observer) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (data) => {
        observer.next(cb(reader.result.toString()));
        observer.complete();
      };
    });
  }

  /** Upload receipts
   * @param receipts The list of receipts to be processed
   * @returns Observable of list of boolean that indicate if a file was uploaded
  */
  private uploadReceipts(receipts: Receipt[], auditIssueId: string): Observable<Receipt[]> {
    if (receipts.length == 0)
      return of([]);
    return forkJoin(receipts.map(receipt => this.uploadReceipt(receipt, auditIssueId)));
  }

  /** Use this method to attach a base64 image data to a expense
   * @param receipt The Receipt with imageData =! null
   * @returns a true if the receipt uploads successfully.
   */
  private uploadReceipt(receipt: Receipt, auditIssueId: string): Observable<Receipt> {
    if (!this.hasImageData(receipt)) {
      this.logService.logError(`Unable to upload receipt: ${receipt.fileName}`, true, 'No Receipt Attached');
      return of(null);
    }

    let receiptInput = new ReceiptInput();
    receiptInput.expenseId = receipt.expenseId;
    receiptInput.enterpriseId = this.userEid;
    receiptInput.file = new ReceiptImage();
    receiptInput.file.fileName = receipt.fileName;
    receiptInput.file.imageDataString = receipt.getFileData();
    receiptInput.periodEnd = this.globalCacheService.getPeriodEnd();
    receiptInput.auditIssueId = auditIssueId;
    receiptInput.receiptType = receipt.receiptType;


    let uploadReceiptRequest = this.api.uploadReceipt(this.supervisorEid, this.viewMode, receiptInput)
    .pipe(map(response => {
      if(response?.staticMessage?.indexOf("The photo image is not clear.") !== -1 && response?.staticMessage != null) {
        this.logService.logError(`Unable to upload ${response?.receipts[0].fileName}. ${response?.staticMessage}`, true, 'No Receipt Attached');
        return null;
      }
      if(response?.staticMessage?.indexOf("MyTime&Expenses is experiencing issues with receipt uploads. Please try again later. If you continue to experience this issue, please contact Technology Support.") !== -1 && response?.staticMessage != null) {
        this.logService.logError(`${response?.staticMessage}`, true, 'No Receipt Attached');
        return null;
      }
      if((response?.staticMessage?.indexOf(".JPG,") !== -1 || response?.staticMessage?.indexOf("jpg,") !== -1) && response?.staticMessage != null) {
        this.logService.logError(`${response?.staticMessage}`, true);
        return null;
      }
      return receipt;
    }))
      .pipe(catchError(err => {
          this.logService.logError(`Unable to upload receipt: ${receipt.fileName}`, true, 'No Receipt Attached');
        return of(null);
      }));

    return this.globalCacheService.handleUploadReceipt(this.globalCacheService.getPeriodEnd(), uploadReceiptRequest);
  }

  private mapReceiptsOutputToReceipt(receiptOutput: ReceiptOutput): ExpenseReceipts {
    let receiptsArray: Receipt[] = [];
    if (receiptOutput.receipts) {
      receiptOutput.receipts.forEach(x => {
        let receipt = new Receipt();
        receipt.id = x.receiptId;
        receipt.fileName = x.fileName;
        receipt.fileUrl = x.receiptURL;
        receipt.expenseId = x.expenseId;
        receipt.receiptType = x.receiptType;
        receipt.fileType = this.mapFileType(x.mimeType?.toLocaleLowerCase());
        receipt.processFileData(x.receiptURL);
        receiptsArray.push(receipt);
      });
    }
    let receipts = new ExpenseReceipts();
    receipts.warningMessages.push(receiptOutput.staticMessage)
    receipts.missingReceiptInfo = receiptOutput.missingReceiptInfo;
    receipts.receipts = receiptsArray;
    receipts.ReceiptTypesRequired = receiptOutput.receiptTypesRequired;

    return receipts;
  }

  private mapReceiptsOutputToDocuments(receiptOutput: ReceiptOutput): TimeReportDocumentation {
    let documents: AdditionalDocumentation[] = [];
    receiptOutput.receipts.forEach(x => {
      let receipt = new AdditionalDocumentation();
      receipt.id = x.receiptId;
      receipt.fileName = x.fileName;
      receipt.fileUrl = x.receiptURL;
      receipt.periodEnd = null;
      receipt.fileType = this.mapFileType(x.mimeType.toLocaleLowerCase());
      receipt.processFileData(x.receiptURL);
      documents.push(receipt);
    });

    let timeReportDocumentation = new TimeReportDocumentation();
    timeReportDocumentation.warningMessages.push(receiptOutput.staticMessage);
    timeReportDocumentation.missingReceiptInfo = receiptOutput.missingReceiptInfo;
    timeReportDocumentation.additionalDocuments = documents;

    return timeReportDocumentation;
  }

  private mapFileType(mimeType: string): ReceiptFileType {
    switch (mimeType) {
      case '.jpg':
        return ReceiptFileType.Image;
      case '.png':
        return ReceiptFileType.Image;
      case '.jpeg':
        return ReceiptFileType.Image;
      case '.gif':
        return ReceiptFileType.Image;
      case '.pdf':
        return ReceiptFileType.Pdf;
      default:
        return ReceiptFileType.Undefined;
    }
  }

  private hasImageData(file: MyteFile): boolean {
    return file.getFileData() != null;
  }

  public isEditable(): Observable<any> {
    let viewMode = this.globalCacheService.getSubordinateMode();
    var p = new Promise(success => {
    let olderThanAYear=this.isOlderThanAYear(this.globalCacheService.getPeriodEnd());
    if(olderThanAYear || viewMode == SubordinatesMode.Supervisor || viewMode == SubordinatesMode.Approver){
      success(false);
    }else{
      success(true);
    }});
    return from(p);
  }

  public isOlderThanAYear(periodEnd: Date):boolean {
    let currentDate = new Date();
      let olderThanAYear:boolean;
      let limitDate = new Date();
      limitDate.setFullYear(currentDate.getFullYear()-1)
      return olderThanAYear= this.getShortDate(periodEnd)<this.getShortDate(limitDate);
  }

  private getShortDate(shortdate: Date): Number {
    return shortdate.setHours(0,0,0,0);
  }

  public setEditable(params: any, component: any): Observable<boolean> {
    component.subordinateMode = this.globalCacheService.getSubordinateMode();
    let userRole = this.globalCacheService.getUserRole();
    let expenseEditableForAuditor = this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.expenseEditableForAudit);
    component.editable = false;
    if (params.context?.expenseGridParamsService?.viewMode == SubordinatesMode.Audit) {
      component.isReadOnly = (!expenseEditableForAuditor && component.subordinateMode === SubordinatesMode.Audit) || 
                             (expenseEditableForAuditor && userRole === UserRole.AuditSupport);                               
      return this.isEditable().pipe(map(editable => {
        component.editable = (component.isReadOnly ? !editable : editable);
        return component.editable;
      }));
    } else {
      component.editable = params.context?.expenseGridParamsService?.isEditable;
      return of(component.editable);
    }
  }
}
