import { Component } from '@angular/core';
import { ExpenseFieldWithValidationResult } from '@shared/clients/expense-api-client';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { ReceiptRequirementTypes } from '@shared/models/expense/receipt/expense-receipt-requirement';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { ReceiptsService } from '@shared/services/receipts/receipts.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExpenseGridEventsService } from '../../../shared/services/events/expense-grid-events.service';
import { ExpenseGridService } from '../../../shared/services/grids/expense-grid.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { icons } from './constants/expenseTypeIcons.constants';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';
@Component({
    selector: 'myte-expense-grid-expense-type-renderer',
    templateUrl: './expense-grid-expense-type-renderer.component.html',
    styleUrls: ['./expense-grid-expense-type-renderer.component.sass']
})
export class ExpenseGridExpenseTypeRendererComponent implements ICellRendererAngularComp {
    public params: ICellRendererParams<ExpenseGridItem, ExpenseFieldWithValidationResult>;
    public isGroupByTrip: boolean;
    public editable: boolean;
    public countryKey: string;
    public periodEnd: Date;
    public icon: string;
    public isGCUser: boolean;
    public timeReportStatus: string;

    constructor(
        protected receiptsService: ReceiptsService,
        protected expenseGridService: ExpenseGridService,
        protected expenseGridEventService: ExpenseGridEventsService,
        public cacheService: GlobalCacheService,
        public actionService: ActionStoreService,
        public timeReportStoreService: TimeReportStoreService) { }

    public agInit(params: ICellRendererParams<ExpenseGridItem, ExpenseFieldWithValidationResult>): void {
        this.params = params;
        this.editable = false;
        this.receiptsService.setEditable(params, this).subscribe(editable => {
            this.editable = editable;
          });
        this.isGroupByTrip = this.params.context?.expenseGridParamsService?.isGroupByTrip;
        this.countryKey = this.params.context?.expenseGridParamsService?.countryUser;
        this.periodEnd = this.params.context?.expenseGridParamsService?.periodEnd;
        this.timeReportStatus = this.params.context?.expenseGridParamsService?.timeReportStatus;

        this.icon = this.setIcon(params?.value?.value);

        if(this.timeReportStatus != undefined) {
            this.isGCUser = this.params.context?.expenseGridParamsService?.shouldShowReceiptType;
        }
    }

    public refresh(): boolean {
        return false;
    }

    public invokeParentMethod() {
        this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`);
    }

    public setIcon(value: string) : string {
        let totalAmountValue = value?.substring(0, value.lastIndexOf(':'));
        if (totalAmountValue === "Total Reimbursement") {
            return "";
        }
        value = value?.replace(/\s/g, '');
        return icons[value] ? icons[value] : icons['default'];
    }

    public dropHandler(event): void {
        if (!this.isGCUser) {
            if (!this.editable || this.isGroupByTrip) return;

            event.preventDefault();
            event.stopPropagation();
            this.removeRowClass(event, 'row-file-dragged');
            event.dataTransfer.effectAllowed = 'copyMove';

            this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
            if (this.params?.data?.type.value === 'Salary Supplement' && this.params.data.receipt.type != ReceiptRequirementTypes.Both) {
                this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
                    shouldInitialize: true,
                    periodEnd: this.periodEnd
                });
                return;
            }
            this.receiptsService.attachReceipts(event.dataTransfer.files, this.params.data.id, '')
                .subscribe(() => {
                    this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
                        shouldInitialize: true,
                        periodEnd: this.periodEnd
                    });
                });
        }
    }

    public dragEnterHandler(event): boolean {
        if (!this.editable || this.isGroupByTrip) return;
        event.preventDefault();
        event.stopPropagation();
        return true;
    }

    public dragOverHandler(event): boolean {
        if (!this.editable || this.isGroupByTrip) return;
        event.preventDefault();
        event.stopPropagation();
        this.setRowClass(event, 'row-file-dragged');
        return true;
    }

    public dragLeaveHandler(event): boolean {
        if (!this.editable || this.isGroupByTrip) return;
        event.preventDefault();
        event.stopPropagation();
        this.removeRowClass(event, 'row-file-dragged');
        return true;
    }

    private setRowClass(event, className: string): void {
        const row = this.getRow(event);
        if (row && !row.classList.contains(className)) {
            row.classList.add(className);
        }
    }

    private removeRowClass(event, className: string): void {
        const row = this.getRow(event);
        if (row)
            row.classList.remove(className);
    }

    private getRow = (event) => {
        const path = event.path || (event.composedPath && event.composedPath()) || this.customComposedPath(event);
        if (path) {
            return path.find(p =>
                p.attributes && p.attributes.role && p.attributes.role.value == 'row' && p.attributes['row-index']);
        }
        return false;
    }

    private customComposedPath(event): any[] {
        let path = [];
        let currentElement = event.target;

        while (currentElement) {
            path.push(currentElement);
            if (currentElement.tagName === 'HTML') {
                path.push(document);
                path.push(window);
                return path;
            }
            currentElement = currentElement.parentElement;
        }
        return path;
    }

}