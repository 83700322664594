import { Component } from '@angular/core';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { ReceiptRequirementTypes } from '@shared/models/expense/receipt/expense-receipt-requirement';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { ReceiptsService } from '@shared/services/receipts/receipts.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExpenseAmount } from '../../../../shared/models/expense/expense-amount';
import { LocalizeFormatUtils } from '../../../../shared/utils/localizeFormater.utils';
import { NumberFormatUtils } from '../../../../shared/utils/numberFormater.utils';
import { ExpenseGridEventsService } from '../../../shared/services/events/expense-grid-events.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';

@Component({
  selector: 'myte-expense-grid-amount-renderer',
  templateUrl: './expense-grid-amount-renderer.component.html',
  styleUrls: ['./expense-grid-amount-renderer.component.sass']
})
export class ExpenseGridAmountRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<ExpenseGridItem, ExpenseAmount>;
  public value: ExpenseAmount;
  public decimalPlacesFormatter: string;
  public toolTip: string;
  public expenseAmount: string;
  public taxTrueUpValue: string;
  public isGroupByTrip: boolean;
  public editable: boolean;
  public periodEnd: Date;
  public countryKey: string;
  public isGCUser: boolean;
  public timeReportStatus: any;

  constructor(
    protected receiptsService: ReceiptsService,
    protected expenseGridEventService: ExpenseGridEventsService,
    public cacheService: GlobalCacheService,
    public actionService: ActionStoreService,
    public timeReportStoreService: TimeReportStoreService) { }

  public agInit(params: ICellRendererParams<ExpenseGridItem, ExpenseAmount>): void {
    this.params = params;
    this.countryKey = this.params?.context?.expenseGridParamsService?.countryUser;
    this.isGroupByTrip = this.params?.context?.expenseGridParamsService?.isGroupByTrip;
    this.value = this.params.value;
    this.periodEnd = this.params.context?.expenseGridParamsService?.periodEnd;
    this.expenseAmount = NumberFormatUtils.formatNumberToLocaleString(this.value.amount, 2);
    this.taxTrueUpValue = NumberFormatUtils.formatNumberToLocaleString(Number(this.value.taxTrueUpValue), 2);
    this.decimalPlacesFormatter = this.value.decimalPlacesFormatter(this.value.decimalPlaces);
    this.value.amountToDisplay = this.formatValueToLocale(this.value.amount.toString(), this.value.decimalPlaces);
    this.editable = false;
    this.receiptsService.setEditable(params, this).subscribe(editable => {
      this.editable = editable;
    });
    this.timeReportStatus = params.context?.expenseGridParamsService?.timeReportStatus;
    if (this.params.data) {
      let isThousandSeparatorWithComma = this.value.amountToDisplay.includes(",");
      this.toolTip = LocalizeFormatUtils.localizeTooltip(this.params.data.expenseChargeHistoryToolTipText, true, true, isThousandSeparatorWithComma);
    }

    if (this.value.code == "EX05" && this.value.isCarPlan) {
      this.value.amountToDisplay = "N/A Car Plan";
      this.toolTip = "Car Plan Km/Mile expenses are not reimbursed to the employee";
    }

    if(this.timeReportStatus != undefined){
      this.isGCUser = params.context?.expenseGridParamsService?.shouldShowReceiptType;
    }
    
  }

  public refresh(): boolean {
    return false;
  }

  public invokeParentMethod(): void {
    this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`);
  }

  public getReimbursement(currency = false): string {
    let reinmursement = NumberFormatUtils.formatNumberToLocaleString(Number((+(this.value.amount + Number(this.value.taxTrueUpValue)))), 2, 2);
    return (currency) ? reinmursement : reinmursement;
  }

  public formatValueToLocale(value: string, decimalPlaces: number): string {
    if (isNaN(Number(value)) || value === null || value === undefined || value === '') { return value; }
    return NumberFormatUtils.formatNumberToLocaleString(parseFloat(value), decimalPlaces);
  }

  public dropHandler(event): void {
    if (!this.isGCUser) {
      if (!this.editable) return;
      if (this.isGroupByTrip) return;
      event.preventDefault();
      event.stopPropagation();
      this.removeRowClass(event, 'row-file-dragged');
      event.dataTransfer.effectAllowed = 'copyMove';

      this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
      if (this.params?.data?.type.value === 'Salary Supplement' && this.params.data.receipt.type != ReceiptRequirementTypes.Both) {
        this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
          shouldInitialize: true,
          periodEnd: this.periodEnd
        });
        return;
      }
      this.receiptsService.attachReceipts(event.dataTransfer.files, this.params.data.id, '')
        .subscribe(() => {
          this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
            shouldInitialize: true,
            periodEnd: this.periodEnd
          });
        });
    }
  }

  public dragEnterHandler(event): boolean {
    if (!this.editable) return;
    if (this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    return true;
  }

  public dragOverHandler(event): boolean {
    if (!this.editable) return;
    if (this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.setRowClass(event, 'row-file-dragged');
    return true;
  }

  public dragLeaveHandler(event): boolean {
    if (!this.editable) return;
    if (this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.removeRowClass(event, 'row-file-dragged');
    return true;
  }

  private setRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row && !row.classList.contains(className)) {
      row.classList.add(className);
    }
  }

  private removeRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row)
      row.classList.remove(className);
  }

  private getRow = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || this.customComposedPath(event);
    if (path) {
      return path.find(p =>
        p.attributes && p.attributes.role && p.attributes.role.value == 'row' && p.attributes['row-index']);
    }
    return false;
  }

  private customComposedPath(event): any[] {
    let path = [];
    let currentElement = event.target;

    while (currentElement) {
      path.push(currentElement);
      if (currentElement.tagName === 'HTML') {
        path.push(document);
        path.push(window);
        return path;
      }
      currentElement = currentElement.parentElement;
    }
    return path;
  }
}